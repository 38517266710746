div.price-box {
    margin: 15px 0;

    div.price-actual {
        span.price-old {
            display: block;
            color: #7a7a7a;
            text-decoration: line-through;
        }

        span.actual-label {
            font-size: 24px;
        }

        span.tax-label {
            display: block;
        }
    }

    div.discount-label {
        display: inline-block;
        padding: 0 10px;
        background-color: $promotion;
        color: #FFF;
        font-size: 14px;
    }

    &.discounted {
        span.actual-label {
            color: $promotion;
        }
    }
}

// produkt
article.item-product {
    display: grid !important;
    gap: 5px;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    position: relative;

    figure {
        position: relative;
    }

    p {
        &.product-title {
            margin: 0;

            a {
                font-family: 'Lora', serif;
                font-size: 18px;
                font-weight: 700;
            }
        }

        &.desc {
            margin: 0;
            color: #160D08;
            font-style: 14px;
            font-weight: 400;
        }
    }

    &.item-small {
        p.product-title {
            margin-top: 10px;
        }

        p.desc {
            padding: 0 25px;
            color: #777;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }

        a.catcher {
            justify-self: center;
            margin: 15px 0 20px;
        }

        div.price-qv {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    li.product-flag.discount {
        display: none;
    }
}

// pagination
div#js-product-list {
    div.products+div.row.u-a-i-c {
        margin: 15px 0 0;
    }
}