@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');

// font-family: 'Lora', serif;

p,
input,
textarea,
h1,
li,
h2,
h3,
h4 {
    color: #160D08;
}

* {
    font-family: 'Poppins', sans-serif;
}

@import "abstracts/variables-bootstrap";
@import "bootstrap";
@import "abstracts/mixins";
@import "abstracts/variables";
@import "partials/fonts";

/** LAYOUT **/
@import "layout/header";
@import "layout/footer";
@import "layout/mainmenu";
@import "layout/home";


/** PAGES **/
@import "pages/cart";
@import "pages/category";
@import "pages/home";
@import "pages/order";
@import "pages/product";

@import "partials/commons";
@import "partials/lazysizes";
/** COMPONENT **/
@import "components/alert";
@import "components/block-social";
@import "components/cart";
@import "components/categories";
@import "components/customer";
@import "components/footer";
@import "components/imageslider";
@import "components/offcanvas";
@import "components/products";
@import "components/searchbar";

@import "components/facet";
@import "components/search-widget";
@import "components/slick";
@import "components/slick-theme";
@import "components/productcomments";
@import "components/utilities";
@import "partials/bs_alpha";