// stopka
footer.l-footer {
  margin: 0;
  padding: 0;
}

body:not(#index) footer.l-footer {
  margin-top: 45px;
}

div.footer-container {
  margin: 0;
  padding: 1px 0;
  background-color: #111;

  section#footer-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 0 15px;

    p,
    li {
      margin: 0 0 5px;
      padding: 0;
      border: none;
      color: #FFF;
      font-size: 14px;
    }

    figure {
      width: 86px;
      margin: 20px 0;
      font-size: 6rem;
      line-height: 1rem;
    }

    a {
      margin: 0;
      padding: 0;
      border: none;
      color: #777;
      font-size: 14px;
      @include transition;
    }

    a:hover {
      color: #FFF;
    }

    >div {
      display: block;
      padding: 0;
      background: none;
      border: none;
    }

    p.footer__title {
      margin: 20px 0;
      color: #FFF;
      font-family: 'Lora', serif;
      font-size: 18px;
      font-weight: 700;
      text-transform: initial;
    }

    p.phone,
    p.mail {
      span {
        color: #777;
      }
    }

    div.social-media {
      display: grid;
      grid-auto-flow: column;
      gap: 15px;
      justify-content: start;

      a {
        display: block;
        width: 50px;
        height: 50px;
        background-color: rgba(#B8885B, 0);
        border: 1px solid rgba(#B8885B, .3);
        border-radius: 50%;
        text-indent: -999px;
        overflow: hidden;

        &.instagram {
          background: url('../img/instagram.svg') center center no-repeat;
        }

        &.facebook {
          background: url('../img/facebook.svg') center center no-repeat;
        }

        &:hover {
          background-color: rgba(#B8885B, .1);
          border: 1px solid rgba(#B8885B, 1);
        }
      }
    }
  }

  div.footer-copyright {
    margin-top: 15px;
    padding: 15px 0;
    background-color: #000;

    p {
      margin: 0;
      color: #B7B3AE;
      font-size: 12px;

      span:before {
        content: "|";
        margin: 0 15px;
      }

      a {
        color: #B7B3AE;

        &:hover {
          color: #FFF;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    section#footer-grid {
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: dense;
    }
  }
}