// right handed
div.modal.fade div.modal-dialog__offcanvas {
  right: 0;
  transform: translateX(100%);
}

div.modal.show div.modal-dialog__offcanvas {
  transform: translateX(0);
}

// floating
body#index header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
}

// menu
div.top-navi {
  background-color: #fff;
  .logo {
    font-size: 7.5rem;
    line-height: 1rem;
  }
  div.container > div.rower {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    gap: 15px;
    place-items: center;
    padding: 18px 0;

    div.top-acc {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      gap: 40px;
      justify-self: end;

      @include media-breakpoint-down(xs) {
        gap: 15px;
      }

      div.user {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        gap: 15px;
        align-items: center;

        a {
          color: #fff;
          font-size: 16px;
          font-weight: 600;

          @include media-breakpoint-down(xs) {
            &.link-register,
            &.link-logout {
              display: none;
            }

            + span {
              display: none;
            }
          }
        }

        span {
          color: #777;
        }
      }

      div.mini-cart {
        a {
          display: block;
          padding-right: 8px;

          figure {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 50%;
            margin: 0;
            width: 60px;
            height: 60px;
            position: relative;

            @include media-breakpoint-down(md) {
              width: 50px;
              height: 50px;
            }

            figcaption {
              min-width: 16px;
              height: 16px;
              padding: 0 3px;
              background-color: #b8885b;
              border-radius: 8px;
              color: #fff;
              font-size: 10px;
              font-weight: 600;
              line-height: 16px;
              text-align: center;
              position: absolute;
              top: calc(50% - 8px);
              right: -8px;
            }
          }
        }
      }
    }
  }
}

body#index header {
  div.top-navi {
    background-color: transparent;
  }
}

// menu

.menu {
  height: 70px;
}

.menu-top {
  display: flex;
  justify-content: space-around;
  margin: 0;
  position: relative;

  li.menu__item--current > a,
  li a:hover {
    color: #b8885b;
  }
}

.menu__item-link--top {
  display: block;
  padding: 5px 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.menu__item-link--hassubmenu {
  align-items: center;
}

.menu__item-link--hassubmenu:after {
  content: " ";
  display: inline-block;
  width: 12px;
  height: 7px;
  margin-left: 10px;
  background: url("../img/parent.svg") center center no-repeat;
}

@include media-breakpoint-desktop {
  .menu__item-header {
    height: 100%;
  }

  .menu-sub {
    position: absolute;
    opacity: 0;
    width: auto;
    left: 0;
    top: 100%;
    z-index: -1;
    transition: all 0.5s ease;
  }

  .menu-sub__content > ul {
    padding: 10px;
    background-color: #fff;
    box-shadow: $box-shadow;
  }

  .menu__item--active .menu-sub {
    z-index: 2;
    opacity: 1;
  }

  .menu-sub__list--1 {
    display: flex;
  }

  .menu__item--1 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .menu__item-link--1 {
    text-transform: uppercase;
    color: $black;

    .menu__item-link {
      color: $body-color;
    }
  }
}

//mobile
@include media-breakpoint-mobile() {
  .menu-top {
    flex-direction: column;
    margin-bottom: $spacer;
  }

  .menu-sub {
    width: 100%;
    transition: all 0.3s ease-out;
  }

  //remove padding from container class
  .menu-sub__content {
    padding: 0;
  }

  .menu__item-link--top {
    padding: 0;
  }

  .top-menu-mobile {
    margin-right: -$modal-inner-padding;
    margin-left: -$modal-inner-padding;
  }

  .menu__item {
    //padding: $spacer/2;
    border-bottom: 1px solid $menu-item-border-color;
    border-top: 1px solid $menu-item-border-color;
  }

  .menu__item--top:not(:first-child) {
    border-top: 0;
  }

  .menu__item--sub:last-child,
  .menu__item--sub:first-child:not(:last-child) {
    border-bottom: 0;
  }

  .menu__item-header {
    display: flex;
    justify-content: space-between;
    padding: $menu-item-padding;
  }

  .menu__item-link--nosubmenu {
    padding: $menu-item-padding;
    display: block;
  }

  .menu-sub.show {
    background: $menu-sub-mobile-bg-color;
  }

  //increase offset
  .menu__item--1 {
    .menu__item-link--nosubmenu,
    .menu__item-header {
      margin-left: 10px;
    }
  }

  .menu__item--2 {
    .menu__item-link--nosubmenu,
    .menu__item-header {
      margin-left: 20px;
    }
  }

  .menu__item--3 {
    .menu__item-link--nosubmenu,
    .menu__item-header {
      margin-left: 30px;
    }
  }
}

#mobile_top_menu_wrapper {
  &.modal {
    .modal-header {
      border-bottom: 0;
    }

    .modal-body {
      padding-top: 0;
    }
  }
}

.menu__collapseicon {
  width: 3rem;
  height: 1.5rem;
  display: block;
  font-size: 1.5rem;
  line-height: 1;
  text-align: center;
}

body:not(#index) {
  .menu__item-link--top {
    color: #111;
  }

  div.user a {
    color: #111;
  }

  div.mini-cart figure {
    border: 1px solid #777;
  }
}

div#_mobile_top_menu {
  .menu__item-link--hassubmenu:after {
    content: none;
  }

  div.menu-sub.show {
    background: #fff;
  }

  .menu__item-link--top {
    color: #111;
  }
}

button#menu-icon {
  border: 1px solid rgba(#111, 0.5);

  i {
    color: #111;
  }
}

body#index button#menu-icon {
  border: 1px solid rgba(#fff, 0.5);

  i {
    color: #fff;
  }
}
