@mixin transition {
  transition: all 200ms ease;
}

@mixin gradient($direction, $color1, $color2) {
  background: $color1;
  background: linear-gradient(to $direction, $color1 0%, $color2 100%);
}

.rc:after {
  border-color: #ab8869 !important;
  border-right-color: transparent !important;
}

html {
  background-color: #000;
}

body {
  background-color: #FFF;
}

div.category-cover {
  display: none;
}

div.rower {
  margin-right: -15px;
  margin-left: -15px;

  @include media-breakpoint-down(xs) {
    margin: 0;
  }
}

button,
input {
  &[disabled="disabled"] {
    cursor: not-allowed;
  }
}

.heading,
h2 {
  margin: 45px 0;
  padding: 0 0 15px 0;
  color: #111;
  font-family: 'Lora', serif;
  font-size: 36px;
  font-weight: 700;
  position: relative;

  &.text-center {
    &:after {
      left: calc(50% - 33px);
    }
  }

  &:after {
    content: " ";
    background-color: #B8885B;
    width: 66px;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

section h2 {
  text-align: center;

  &:after {
    left: calc(50% - 33px);
  }
}

section figure {
  margin: 0;
  max-width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

a.catcher {
  display: inline-flex;
  align-items: center;
  color: #B8885B;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .5px;
  text-transform: uppercase;

  svg {
    margin-left: 15px;
  }
}

a.button {
  display: inline-block;
  padding: 22px 35px;
  @include gradient (right, #B8885B, #D6BB8A);
  border-radius: 32px;
  outline: none;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-transform: uppercase;
  @include transition;

  &:hover {
    @include gradient (right, #B8885B, #B8885B);
  }

  &.small {
    padding: 10px 35px;
    border-radius: 20px;
  }
}

// nasze produkty
section#our-products {
  margin: 0;
  padding: 1px 0 45px;
  background-color: #FFF;

  div.our-products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }

  article {
    border: 1px solid #E5E5E5;

    figure {
      padding: 0 44px;
    }

    a.catcher {
      @include transition;

      &:hover {
        color: #111;
      }

      svg {
        opacity: .65;
      }
    }
  }

  div.unique {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #111;
    padding: 0 25px;
    text-align: center;

    h5 {
      margin: 15px 0;
      color: #FFF;
      font-family: 'Lora', serif;
      font-size: 28px;
      font-weight: 700;
    }

    p {
      color: #777;
    }
  }

  @include media-breakpoint-down(lg) {
    div.unique h5 {
      margin: 10px 0;
      font-size: 24px;
    }
  }

  @include media-breakpoint-down(md) {
    div.our-products {
      grid-template-columns: 1fr 1fr 1fr;
    }

    div.unique {
      grid-column: span 2;
    }
  }

  @include media-breakpoint-down(sm) {
    div.our-products {
      grid-template-columns: 1fr 1fr;
    }

    div.unique {
      grid-column: span 1;
    }
  }

  @media (max-width: 499px) {
    div.our-products {
      grid-template-columns: 1fr;
    }

    div.unique {
      padding: 15px 25px;
    }
  }
}

// contact form
section#footer-above {
  background-color: #DFDFDF;
  padding: 1px 0 45px;
}

section.contact-form {
  width: 100%;

  >form {
    width: 100%;
    max-width: 100%;

    .text-muted {
      display: none;
    }
  }

  section.form-fields {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr 1fr;
    }

    @include media-breakpoint-down(xs) {
      grid-template-columns: 1fr;
    }

    label {
      line-height: 22px;
      margin-bottom: 8px;
    }

    *:focus,
    label {
      outline: none;
      box-shadow: none !important;
    }

    label.custom-file-label,
    input.form-control,
    select.custom-select {
      height: 52px;
      border: 1px solid #CCC;
      border-radius: 26px;
    }

    div.custom-file {
      height: 52px;

      >input.custom-file-input {
        height: 52px;
      }

      >label.custom-file-label {
        overflow: hidden;
        margin: 0;
        padding: 0 $input-padding-x;
        line-height: 52px;

        &:after {
          height: auto;
          padding: 0 $input-padding-x;
          line-height: 52px;
          bottom: 0;
        }

        >span {
          display: block;
          height: auto;
        }
      }
    }

    >div.form-content {
      display: grid;
      grid-template-rows: 30px 1fr;
      grid-column: 2 / 3;
      grid-row: 1 / 4;

      @include media-breakpoint-down(xs) {
        grid-column: auto;
        grid-row: auto;
      }

      textarea {
        padding: 15px $input-padding-x;
        border: 1px solid #CCC;
        border-radius: 26px;
        resize: none;
      }
    }

    div.form-rodo {
      display: grid;
      grid-template-columns: 1fr max-content;
      gap: 20px;
      grid-column: 2 / 3;

      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
      }

      @include media-breakpoint-down(xs) {
        grid-column: auto;
      }

      label {
        font-size: 12px;
      }

      input[type="submit"] {
        justify-self: end;
        grid-column: 2 / 3;
        height: 52px;
        padding: 0 30px;
        background-color: #AC8869;
        border: 2px solid #AC8869;
        border-radius: 26px;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        line-height: 48px;
        text-transform: uppercase;
        @include transition;

        &:hover {
          background-color: #7f634b;
        }

        @include media-breakpoint-down(sm) {
          grid-column: 1 / 2;
        }
      }
    }
  }
}

// o nas
section#about-us {
  padding: 1px 0 45px;
  background: url('../img/about-us.jpg') center center no-repeat;


  @include media-breakpoint-down(md) {
    padding-top: 440px;
    background: #181818 url('../img/about-us-md.jpg') top center no-repeat;
  }

  @include media-breakpoint-down(xs) {
    padding-top: 250px;
    background: #181818 url('../img/about-us-xs.jpg') top center no-repeat;
  }

  h2 {
    color: #FFF;

    @include media-breakpoint-down(md) {
      margin-top: 25px;
    }
  }

  div.about {
    max-width: 540px;

    @include media-breakpoint-down(lg) {
      max-width: 470px;
    }

    @include media-breakpoint-down(md) {
      max-width: 100%;
    }

    p {
      color: #777;
      font-size: 16px;

      strong {
        color: #FFF;
        font-size: 22px;
      }
    }

    a.button {
      margin-top: 25px;
    }
  }
}

// co nas wyróżnia
section#picto {
  div.picto {
    display: grid;
    padding: 35px 0;
    grid-template-columns: 1fr 1fr 1fr;
    border: 1px solid #E5E5E5;


    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }

    >div.item {
      padding: 35px;
      text-align: center;

      @include media-breakpoint-down(sm) {
        padding: 0 15px;
      }

      div {
        height: 100px;

        img {
          width: 100%;
          height: 100%;
          object-fit: none;
        }
      }

      h5 {
        margin: 25px 0;
        color: #111;
        font-family: 'Lora', serif;
        font-size: 18px;
        font-weight: 700;

        @include media-breakpoint-down(xs) {
          margin: 5px 0 10px;
        }
      }

      p {
        color: #777;
        font-size: 14px;
        font-weight: 400;
      }

      &:nth-child(2) {
        border-right: 1px solid #E5E5E5;
        border-left: 1px solid #E5E5E5;
      }
    }
  }
}