div#carousel {
  max-height: 960px;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;

  > svg {
    position: absolute;
    bottom: 25px;
    left: calc(50% - 12px);
  }

  div.slick-list {
    div.slick-track {
      display: flex;
      float: none;
    }
  }

  button.slick-arrow {
    outline: none;
    box-shadow: none;
    justify-content: flex-end;
  }

  figure {
    display: block !important;
    width: 100%;
    height: 960px;
    margin: 0;
    position: relative;

    >a {
      display: block;
      height: 100%;
    }

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    figcaption {
      color: #F2E3D6;
      font-size: 36px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% + 0.5px), calc(-50% + 0.5px + 50px));

      div.rower {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      p.slider-title {
        display: block;
        max-width: 480px;
        margin-bottom: 5px;
        color: #FFF;
        font-family: 'Lora', serif;
        font-size: 54px;
        font-weight: 700;
        line-height: 62px;
      }

      p.slider-desc {
        display: block;
        max-width: 435px;
        color: #FFF;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        position: relative;
      }

      a.slider-button {
        display: flex;
        align-items: center;
        height: 64px;
        margin-top: 25px;
        padding: 22px 35px;
        @include gradient (right, #B8885B, #D6BB8A);
        border-radius: 32px;
        outline: none;
        color: #FFF;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 20px;
        text-transform: uppercase;
        @include transition;

        &:hover {
          @include gradient (right, #B8885B, #B8885B);
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    max-height: 500px;

    figure {
      height: 500px;
    }

    > svg {
      display: none;
    }
  }
}


//.carousel {
//  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
//  margin-bottom: 1.5rem;
//  .direction {
//    z-index: auto;
//  }
//  .carousel-inner {
//    height: 340px;
//    @media (max-width: 767px) {
//      height: auto;
//    }
//  }
//  .carousel-item {
//    height: 100%;
//    img {
//      @media (max-width: 767px) {
//        max-width: 100%;
//        height: auto;
//      }
//      @media (min-width: 768px) {
//      width: 100%;
//      margin-left: 0;
//    }
//    }
//    .caption {
//      position: absolute;
//      @media (min-width: 768px) {
//      bottom: 28px;
//      left: 90px;
//      }
//      @media (max-width: 767px) {
//        bottom: 5px;
//        left: 40px;
//      }
//      color: white;
//      max-width: 340px;
//      .caption-description p {
//        color: white;
//      }
//    }
//    figure {
//      @media (max-width: 767px) {
//        margin: 0;
//      }
//    }
//  }
//  .carousel-control {
//    opacity: 1;
//    .icon-next,
//    .icon-prev {
//      &::before {
//        content: "";
//      }
//      i {
//        font-size: 3.125rem;
//        color: white;
//      }
//      &:hover {
//        i {
//          color: $brand-primary;
//        }
//      }
//    }
//    .icon-prev {
//      left: 1rem;
//    }
//    .icon-next {
//      right: 2rem;
//    }
//    &.left,
//    &.right {
//      background: none;
//    }
//  }
//}