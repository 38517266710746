.search-widget{
  max-width: 450px;
  margin: 0 auto;
}
.search-widget__group{
  position: relative;
}
.search-widget__input-right{
  padding-right: 50px ;
}
.search-widget__btn{
  position: absolute;
  right: 0;
  top: 0;
}
@include media-breakpoint-mobile{
  .search-widget{
    max-width: unset;
  }
}

div.search-wrapper {
  display: grid !important;
  grid-template-columns: 1fr 195px 125px;

  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr 125px;
  }

  > div.input-wrapper {
    input.search_query {
      height: auto;
      background: #FFF url('/img/spyglass.svg') center left 15px no-repeat;
      padding: 0 15px 0 45px;
      border: 1px solid #DDD;
      border-radius: 4px 0 0 4px;
      box-shadow: none;
      line-height: 50px;
    }
  }
  > div.searched_categories {
    @include media-breakpoint-down(xs) {
      display: none;
    }
    > select {
      display: none;
    }
    > div.nice-select {
      overflow: hidden;
      position: relative;
      &.open {
        overflow: visible;
      }
      span.current {
        display: block;
        background-color: #F5F5F5;
        border: solid #DDD;
        border-width: 1px 0;
        font-size: 14px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
        &:after {
          content: url('/img/arrow-down.png');
          margin-left: 10px;
          position: relative;
          top: -1px;
        }
      }
      ul.list {
        background-color: #F5F5F5;
        border: solid #DDD;
        border-width: 0 1px 1px 1px;
        position: absolute;
        right: -1px;
        left: -1px;
        z-index: 99;
        li {
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          cursor: pointer;
          &:hover {
            background-color: #DDD;
          }
          &.focus {
            display: none;
          }
        }
      }
    }
  }
  > button {
    background-color: #AC8869;
    border: none;
    border-radius: 0 26px 26px 0;
    outline: none;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
}